import React from "react"

const Testimonial = props => {
  return (
    <div className="row">
      <div className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto text-center">
        <p className="h2 pb-2">Testimonials</p>
        <br />
        <img
          style={{
            width: "150px",
            borderRadius: "50%",
            marginBottom: "30px",
          }}
          src={require("./../../assets/peter.jpg")}
          alt="Peter Li"
        />

        <p
          style={{
            fontSize: "1.4em",
            color: "#555",
            lineHeight: "1.7",
          }}
        >
          <i>
            "Nishant&apos;s hunger for new technology & passion to solve real
            world problems, has placed him perfectly in the right places at the
            right time to deliver needed solutions."
          </i>
        </p>
        <br />
        <p className="h5">Peter Li</p>
        <p
          style={{
            fontWeight: "300",
          }}
        >
          Chief Information Officer, Interact Technology
        </p>
      </div>
    </div>
  )
}

export default Testimonial
