import React from "react"
import {
  IoIosAppstore,
  IoLogoNodejs,
  IoMdCodeWorking,
  IoMdDesktop,
  IoMdCloudDone,
  IoIosPeople,
} from "react-icons/io"
import Colors from "./../../Theme/theme"

const featsArr = [
  {
    icon: <IoMdDesktop size={25} />,
    txt: "Over 80 products and assets live in production so far",
  },
  {
    icon: <IoMdCodeWorking size={25} />,
    txt: "850,000 lines of clean code sent to production in last 3 years",
  },
  {
    icon: <IoMdCloudDone size={25} />,
    txt: "More than 150 successful micro-services migrated to the cloud",
  },
  {
    icon: <IoIosPeople size={25} />,
    txt: "Over 3 million customers have experienced my products as we speak",
  },
  {
    icon: <IoIosAppstore size={25} />,
    txt:
      "Launched the first native iOS app with a Firebase CMS in under 3 months",
  },
  {
    icon: <IoLogoNodejs size={25} />,
    txt: "Built and deployed over 600 serverless APIs",
  },
]

const Achievements = props => {
  return (
    <div
      className="row mt-5 pb-5 text-center"
      style={{
        backgroundColor: Colors.accent2,
        color: "white",
        backgroundImage: `url(${require("./../../assets/feats-text-bg.png")})`,
        backgroundSize: "contain",
      }}
    >
      <div className="col-12 px-0 mx-auto">
        <img
          style={{ width: "100%" }}
          src={require("./../../assets/feats-milestones.png")}
          alt="milestones graphic"
        />
      </div>
      <div className="col-11 col-sm-11 col-md-7 col-lg-10 mx-auto pb-5">
        <p className="mb-3 text-center" style={{ fontSize: "1.8em" }}>
          Milestones &amp; Achievements
        </p>
        <br />

        <div className="d-flex flex-column flex-lg-row align-items-lg-center">
          <div style={{ flex: 1, textAlign: "center" }}>
            <img
              src={require("./../../assets/achievements.svg")}
              alt="Achievements"
              className="w-50 py-3"
            />
          </div>

          <div
            className="pt-3 pt-lg-2 px-sm-5 mx-sm-5 mx-md-auto"
            style={{
              flex: 1.2,
              fontWeight: "300",
              letterSpacing: "1px",
              fontSize: "0.95em",
            }}
          >
            {featsArr.map(feat => (
              <div key={feat.txt} className="d-flex align-items-center py-2">
                {feat.icon} &nbsp;
                <p className="mb-0 ml-2 text-left">{feat.txt}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Achievements
