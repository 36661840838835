import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import { MdArrowDropDown } from "react-icons/md"

import BaseLayout from "./../Layouts/BaseLayout"
import Colors from "./../Theme/theme"

import MainTitles from "./../components/MainTitles/MainTitles"
import IntroSection from "./../components/IntroSection/IntroSection"
import SkillsCard from "../components/SkillsCards/SkillsCards"
import Achievements from "./../components/Achievements/Achievements"
import Testimonial from "./../components/Testimonial/Testimonial"
import Footer from "./../components/Footer/Footer"
import Head from "./../components/Head/Head"

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.customData = props.data
  }
  render() {
    return (
      <BaseLayout>
        <Head pageTitle="Home" />
        <br />
        <br />
        <br />
        <MainTitles />

        <div className="row text-center">
          <img
            className="mx-auto mt-4 mb-4"
            src={require("./../assets/bw-profile-pic.jpg")}
            style={{ width: "200px", height: "200px", borderRadius: "50%" }}
            alt="Nishant Kaushish"
          />
        </div>

        <div className="text-center mt-3">
          <Link
            to="/#skills-cards-container"
            className="btn pl-4"
            style={{ background: Colors.accent, color: "white" }}
          >
            See how I can help <MdArrowDropDown size={"2em"} />
          </Link>
        </div>

        <IntroSection />

        <SkillsCard />
        <br />
        <br />
        <br />
        <br />
        <div className="row mt-5 pt-5" id="get-in-touch-btn-div">
          <div className="col-10 col-md-12 text-center mx-auto">
            <p style={{ fontSize: "1.7em" }}>Want to collaborate or hire me?</p>
            <p style={{ fontWeight: "200" }}>
              Let&apos;s chat and explore how I can help. Reach out anytime.
            </p>
            <br />
            <TransitionLink
              to="/contact"
              className="btn btn-md px-5 text-light"
              style={{ backgroundColor: Colors.primary }}
              exit={{ length: 0.3 }}
              entry={{ delay: 0.5 }}
            >
              Get in touch
            </TransitionLink>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Achievements />

        <br />

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Testimonial />
        <br />
        <br />
        <br />
        <br />
        <br />

        <br />
        <br />

        <Footer />
      </BaseLayout>
    )
  }
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        author
      }
    }
  }
`

export default HomePage
