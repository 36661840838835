import React from "react"
import { MdCode, MdDns, MdPhoneAndroid } from "react-icons/md"
import Colors from "../../Theme/theme"
import styles from "./SkillsCards.module.css"

const cardsArr = [
  {
    icon: <MdCode size={70} color={Colors.accent} />,
    iconAlt: "Front End Icon",
    title: "Front End",
    description:
      "Converting designs to a live interactive mode is an unusually joyful activity.",
    loveText: "Things I have created",
    loveEntries:
      "Microsites, Landing Pages, Single Page Applications & Business Intelligence Tools",
    toolsText: "Tools & Frameworks I use",
    tools:
      "HTML, CSS / SCSS, Javascript, React / Redux, Boostrap, Material UI, Vue / Vuex, Jest, Vuetify",
  },
  {
    icon: <MdDns size={70} color={Colors.accent} />,
    iconAlt: "Back End Icon",
    title: "Back End",
    description:
      "It’s equally joyful to pump real power into the front-end with data, APIs and cloud scalable solutions.",
    loveText: "Experiences I draw from",
    loveEntries:
      "API Design, Migrating Micro-services, Serverless Lambdas for Cloud Magic",
    toolsText: "Dev Tools",
    tools:
      "NodeJS, Jest / Mocha / Chai, MongoDB, AWS DynamoDB, AWS Lambda, AWS API Gateway, Firebase, Heroku, Postman",
  },
  {
    icon: <MdPhoneAndroid size={70} color={Colors.accent} />,
    iconAlt: "Mobile Icon",
    title: "Mobile",
    description:
      "Building native & hybrid apps was my second enlightenment after Webpack",
    loveText: "What I have built",
    loveEntries:
      "Launched my own app Sublimely Media (provides clutter-free Australian news)",
    toolsText: "Tools of choice",
    tools:
      "Swift (IOS), Xcode, Simulator, React Native, Expo, Android Studio, NativeBase",
  },
]

const SkillsCard = props => {
  return (
    <div
      id="skills-cards-container"
      className="row d-flex flex-column flex-lg-row justify-content-lg-center px-3 px-sm-5 py-0"
      style={{
        border: "none",
        position: "relative",
      }}
    >
      <div
        className="col-12"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          height: "150px",
          backgroundImage: `url(${require("./../../assets/skills-card-bg.png")})`,
          backgroundSize: "contain",
        }}
      ></div>
      {cardsArr.map(card => (
        <div
          className={`${styles.single_card} col col-lg-4 text-center py-5 mx-0`}
          key={card.iconAlt}
        >
          <p className="pb-3">{card.icon}</p>

          <p style={{ fontSize: "1.4em" }}>{card.title}</p>

          <p className={`px-3 ${styles.description}`}>{card.description}</p>
          <br />

          <p
            style={{
              color: Colors.accent,
              fontWeight: "400",
              fontSize: "1em",
            }}
          >
            {card.loveText}
          </p>
          <p className={styles.description}>{card.loveEntries}</p>

          <br />

          <p
            style={{
              color: Colors.accent,
              fontWeight: "400",
              fontSize: "1em",
            }}
          >
            {card.toolsText}
          </p>

          <div className="d-flex flex-wrap justify-content-center">
            {card.tools.split(",").map(tool => (
              <p
                key={tool}
                className="my-2 py-1 mx-2 px-3"
                style={{
                  fontWeight: "400",
                  borderRadius: "20px",
                  color: "#fff",
                  fontSize: "0.9em",
                  background: "#a24bdf",
                  boxShadow: "2px 2px 10px 0px #d3d3d3",
                }}
              >
                {tool}
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default SkillsCard
