import React from "react"
import Colors from "./../../Theme/theme"
import styles from "./IntroSection.module.css"

// mt-5 py-5
const IntroSection = props => {
  return (
    <div
      className="row mt-5"
      style={{
        backgroundColor: Colors.primary,
        backgroundImage: `url(${require("./../../assets/intro-text-bg.png")})`,
      }}
    >
      <div className="col-12 px-0 mx-auto">
        <img
          style={{ width: "100%" }}
          src={require("./../../assets/intro-bg.png")}
          alt="bubbles graphic"
        />
      </div>
      <div className="col-10 col-md-8 mx-auto pt-5 text-center text-white">
        <p className={styles.main_title}>
          Welcome to my corner on the internet
        </p>
      </div>

      <div className="col-10 col-md-7 col-lg-6 mx-auto pb-5 text-center text-white">
        <p className={styles.main_subtitle}>
          Every company has unique engineering problems which I love solving and
          eliminating. As a self-taught programmer, I&apos;ve developed a broad
          skillset across modern technologies by building elegant solutions -
          from simple forms, landing pages to RESTful APIs, SaaS application to
          iOS and android apps. Based in Sydney, fluent in Node, React, Vue &
          AWS.
        </p>
      </div>
    </div>
  )
}

export default IntroSection
